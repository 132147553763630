import React, { useState, useEffect, useRef } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, CircularProgress, Button, Menu, MenuItem } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import backend from "../../api";
import sessionstorage from "sessionstorage";
import { useNavigate } from "react-router-dom"
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import DangerousIcon from "@mui/icons-material/Dangerous";
import { setNavBtn } from "../../redux-store/auth/actions";
import axios from 'axios';
import { pdfjs, Document, Page } from 'react-pdf';

import { toast } from "react-toastify";
import Classes from './PitchDashboard.module.css'
import CardItem from "./CardItem";
import UpgradePopup from "../../Global-Components/UpgradePopup/UpgradePopup";
import PurchaseUploadsPopup from "../../Global-Components/PurchaseUploadsPopup/PurchaseUploadsPopup";
import MobileVersionPopup from "../../Global-Components/MobileVersionPopup/MobileVersionPopup";
import { setUserData } from "../../redux-store/auth/actions";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import UploadPopup from "../../Global-Components/UploadPopup/UploadPopup";
import LearnMorePopup from "../../Global-Components/LearnMorePopup/LearnMorePopup";
import { setCompanyInfo } from "../../redux-store/auth/actions";
import { setDeckId } from "../../redux-store/auth/actions";
import OnBoardingSuccessPopup from "../../Global-Components/OnBoardingSuccessPopup/OnBoardingSuccessPopup";
import { AnimatePresence } from "framer-motion";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function PitchDashboard() {
  const { user } = useSelector((state) => state.auth);
  // console.log(user);
  const analyze = useRef(null);

  const [userFiles, setUserFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editState, setEditState] = useState({});
  const [editing, setEditing] = useState(false);
  const [fileName, setFileName] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [newFileName, setNewFileName] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // For search query
  const [sortOrder, setSortOrder] = useState(""); // For sort order
  const [filteredFiles, setFilteredFiles] = useState([]); // For sorted/filtered files
  const [uploadBrochure, setUploadBrochure] = useState([]);
  const [brochureLoading, setBrochureLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [openUploadPopup, setopenUploadPopup] = useState(false);

  const onBoarding = useSelector((state) => state.companyInfo.info);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [loadingFiles, setLoadingFiles] = useState(false);

  const [storedFiles, setStoredFiles] = useState([]);
  const isSortMenuOpen = Boolean(sortAnchorEl);

  const [dots, setDots] = useState('...');
  const [pdfTypeError, setpdfTypeError] = useState(false);
  const [pdfSizeError, setpdfSizeError] = useState(false);

  const dispatch = useDispatch();
  const handleImageClicked = () => {
    if (user.remainingDecks > 0) {
      fileInputRef.current.click();
    } else {
      setopenUpgradePopup(true);
    }
  }
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const [openlearnMorePopup, setopenlearnMorePopup] = useState(false);
  const closeLearnMorePopup = () => {
    setopenlearnMorePopup(false);
  }


  const [openUpgradePopup, setopenUpgradePopup] = useState(false);
  const closeUpgradePopup = () => {
    setopenUpgradePopup(false);
  }
  const [openPurchaseUploadsPopup, setopenPurchaseUploadsPopup] = useState(false);
  const closePurchaseUploadsPopup = () => {
    setopenPurchaseUploadsPopup(false);
  }
  const [openConfirmPopup, setopenConfirmPopup] = useState(false);
  const closeConfirmPopup = () => {
    setopenConfirmPopup(false);
  }

  // const [openUploadPopup, setopenUploadPopup] = useState(false);
  const closeUploadPopup = () => {
    setopenUploadPopup(false);
  }

  const navigateTo = useNavigate();

  const handleEditChange = (id, value) => {
    setEditState({ ...editState, [id]: value });
  };

  const userID = user?._id;

  useEffect(() => {
    const fetchData = async () => {
      try {

        // Check if companyName exists in database
        const response = await backend.get(`/onboarding/?userID=${userID}`);
        // console.log(response.data[0])
        // If response.data is not empty, companyName exists in database
        if (response.data.length > 0) {
          dispatch(setCompanyInfo(response.data[0]))
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  useEffect(() => {
    sessionstorage.setItem("navBtn", "Pitch Deck Dashboard")

    const refresh = sessionstorage.getItem("refresh")
    if (refresh === "yes") {
      sessionstorage.setItem("refresh", "no");
      window.location.reload();
    }
  }, [])
  useEffect(() => {
    // Define the async function inside useEffect
    const fetchUserFiles = async () => {
      try {
        setLoading(true); // Set loading to true at the beginning of the request
        const res = await backend.get(`/deck?userID=${userID}`);
        console.log(res.data)
        setUserFiles(res?.data);
        // setFilteredFiles(res?.data);
        console.log(res?.data);
      } catch (error) {
        console.error("Failed to fetch user files:", error);
        // Handle error state as needed
      } finally {
        setLoading(false); // Set loading to false after the request is complete
      }
    };

    // Call the async function
    fetchUserFiles();
  }, []);

  useEffect(() => {
    if (!userFiles || !Array.isArray(userFiles)) {
      // Handle the case when userFiles is not defined or not an array
      return;
    }

    // Filter and sort userFiles whenever searchQuery or sortOrder changes
    let result = [...userFiles];

    if (searchQuery) {
      result = result.filter(file =>
        file.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (sortOrder === "name") {
      result.sort((a, b) => a.localeCompare(b));
    }
    // else if (sortOrder === "date") {
    //   result.sort((a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt));
    // }

    setFilteredFiles(result);
  }, [searchQuery, sortOrder, userFiles]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  console.log(filteredFiles)

  // const DeleteFile = async (id) => {

  //   const UserId = user._id;
  //   // setLoading(true)
  //   try {
  //     const response = await backend.delete(`/deck/${id}`);
  //     // Filter the response data to include only the required objects
  //     const filteredFiles = userFiles?.filter(
  //       (item) => item._id !== id
  //     );
  //     // setLoading(false);
  //     // console.log(filteredChats);
  //     setUserFiles(filteredFiles);
  //     console.log(response);
  //     window.location.reload();
  //     // setLoading(false)
  //   } catch (error) {
  //     console.error(error);
  //     // toast.error("Error saving story");
  //     setLoading(false)
  //   }
  // };

  async function moveToAnalyzer(url, deckId) {
    localStorage.setItem('pitch', url)

    await dispatch(setDeckId(deckId))
    sessionstorage.setItem("navBtn", 'Pitch Deck Analyzer')
    const getNavBtn = sessionstorage.getItem("navBtn")
    if (getNavBtn === 'Pitch Deck Analyzer') {
      navigateTo(`/analyzer/${deckId}`)
      console.log("ANALYZINGGG>....")
      // window.location.reload();
    }
  }

  const handleEditClick = (name, index) => {
    setEditing(true);
    // setFileName(name);
    setNewFileName(name)
    setSelectedItem(index)
  };

  const handleSaveClick = async (id) => {

    // const UserId = user._id;
    // setLoading(true)
    const body = {
      name: newFileName
    }
    try {
      const response = await backend.put(`/deck/${id}`, body);


      console.log(response);
      window.location.reload();
      // setLoading(false)
    } catch (error) {
      console.error(error);
      // toast.error("Error saving story");
      setLoading(false)
    }
  };

  const handleFileNameChange = (event) => {
    setNewFileName(event.target.value);
  };

  const handleClick = (e) => {
    // Check if the click was on an empty space
    if (e.target === e.currentTarget) {
      setEditing(false);
    }
  };

  const handleSortMenuClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortAnchorEl(null);
  };

  const handleSortOptionClick = (sortOption) => {
    setSortOrder(sortOption)
    handleSortMenuClose();
  };

  const [ImageUrl, setImageUrl] = useState(null);
  const renderFirstPageImage = async (file) => {
    const page = await getPageAsImage(file, 1); // Fetch the first page as an image
    setImageUrl(URL.createObjectURL(page));
  };

  const handleOpenUploadPopup = () => {
    if (user?.subscription === 'free' && user.remainingDecks <= 0) {
      setopenUpgradePopup(true);
      return;
    }
    if ((user?.subscription === 'monthly' || user?.subscription === 'yearly') && user.remainingDecks <= 0) {
      setopenPurchaseUploadsPopup(true);
      return;
    }
    setopenUploadPopup(true)
  }

  const getPageAsImage = async (file, pageNumber) => {
    const pdfData = await file.arrayBuffer();
    const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
    const page = await pdf.getPage(pageNumber);

    const viewport = page.getViewport({ scale: 1.5 }); // Adjust scale as needed
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport
    };

    await page.render(renderContext).promise;
    const imageData = canvas.toDataURL('image/png');
    return fetch(imageData).then(res => res.blob());
  };

  const uploadMedia = async (files, name) => {
    const isValid = Array.from(files).every((file) => file.type.includes("application"));
    console.log(files)
    if (!isValid) {
      alert("File format not supported");
      return;
    }

    setBrochureLoading(true);
    try {
      const formData = new FormData();
      Array.from(files).forEach((file) => formData.append("files", file));
      console.log(formData)
      const response = await axios.post("https://evala-server-6356f19fc5a0.herokuapp.com/api/deck/upload", formData)
      console.log(response)

      if (name === "uploadBrochure") {
        const updatedFiles = response.data.data.map((d) => ({ ...d, key: d.name }));
        setUploadBrochure(prev => [...prev, ...updatedFiles]);
      }
      // toast.success("Files uploaded successfully");
    } catch (error) {
      alert(error.response?.data?.message || error.message || "An error occurred");
    } finally {
      setBrochureLoading(false);
      // setopenConfirmPopup(true);
      setTimeout(() => {
        if (analyze.current) {
          analyze.current.click();
        }
      }, 500);
    }
  };

  const handleInputChange = (e) => {
    const files = e.target.files;
    const name = e.target.name;
    handleFileChange(files, name);
  };

  const handleFileChange = (files, name) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type !== 'application/pdf') {
        setpdfTypeError(true);
        setTimeout(() => {
          setpdfTypeError(false);
        }, 5000);
        return;
      }

      const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
      if (file.size > maxSize) {
        setpdfSizeError(true);
        setTimeout(() => {
          setpdfSizeError(false);
        }, 5000);
        return;
      }

      closeUploadPopup();
      uploadMedia(files, name);
    }
  }

  // const handleFileChange = (e) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const file = e.target.files[0];
  //     if (file.type !== 'application/pdf') {
  //       setpdfTypeError(true);
  //       setTimeout(() => {
  //         setpdfTypeError(false);
  //       }, 5000);
  //       return;
  //     }

  //     const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
  //     if (file.size > maxSize) {
  //       setpdfSizeError(true);
  //       setTimeout(() => {
  //         setpdfSizeError(false);
  //       }, 5000);
  //       return;
  //     }

  //     closeUploadPopup();
  //     uploadMedia(e.target.files, e.target.name);
  //     // renderFirstPageImage(file);
  //   }
  // };

  const handleIconClick = () => {
    if (user.remainingDecks > 0) {
      document.getElementById('formFile').click();
      console.log("allowed");
    } else {
      console.log("not allowed");
      setopenUpgradePopup(true);
    }
  };

  const handleRemoveFile = (index) => {
    setUploadBrochure(current => current.filter((_, i) => i !== index));
  };
  const extractFileNames = (name) => {
    // Split the name by "/" and take the last part as the file name
    const parts = name?.split('/');
    return (parts[parts?.length - 1]);

  };

  console.log(uploadBrochure)
  const companyId = onBoarding?._id;

  const handleSave = async (e) => {
    e.preventDefault();

    const Files = [...storedFiles, ...uploadBrochure.map(file => file.imgurl).filter(Boolean)]; // Spread both arrays to ensure a flat structure

    const newDeck = uploadBrochure[0].imgurl;
    const body = { url: newDeck, userId: userID, companyId: companyId, adminDeck: true };


    console.log(body);
    try {
      const response = await backend.post(`/deck/`, body);
      console.log(response);

      if (response?.data) {
        await dispatch(setDeckId(response?.data?._id))
        UpdateUserDeckNo(response?.data?._id);


        // window.location.reload();
        // alert("Data saved successfully");
      }

    } catch (error) {
      console.error("Failed to post data to server", error);
      toast.error("Failed to save data");
    }
  };

  const handleSaveWithoutRedirect = async (e) => {
    // e.preventDefault();
    setopenConfirmPopup(false);
    const Files = [...storedFiles, ...uploadBrochure.map(file => file.imgurl).filter(Boolean)]; // Spread both arrays to ensure a flat structure

    const newDeck = uploadBrochure[0].imgurl
    const body = { url: newDeck };

    // console.log(body);
    try {
      const response = await backend.post(`/deck/`, body);
      console.log(response);

      if (response?.data) {

        window.location.reload();
        // alert("Data saved successfully");
      }

    } catch (error) {
      console.error("Failed to post data to server", error);
      toast.error("Failed to save data");
    }
  };

  const UpdateUserDeckNo = async (deckId) => {

    const newRD = user.remainingDecks - 1;
    const newUD = user.uploadedDecks + 1;
    const UserToken = user?.token;
    const UserExists = user?.exists;

    try {
      setLoadingFiles(true)
      const body = {
        remainingDecks: newRD,
        uploadedDecks: newUD
      }
      const response = await backend.put(`/user/${userID}`, body);

      // setStoredFiles(response?.data?.UpdateUser?.files)
      // setUserFiles(response?.data?.UpdateUser?.files);
      // setFilteredFiles(response?.data?.UpdateUser?.files);
      console.log(response?.data?.UpdateUser?.files);
      await dispatch(setUserData({
        ...response.data.UpdateUser,
        token: UserToken,
        profileExists: UserExists,
      }))
      console.log(response)
      localStorage.setItem('deckStatus', 'new')
      moveToAnalyzer(uploadBrochure[0].imgurl, deckId);

      setLoadingFiles(false)

    } catch (error) {
      console.error("Failed to get data from server", error);

    }
  };

  const getUserDetails = async () => {
    const UserToken = user?.token;
    const UserExists = user?.exists;
    try {
      setLoadingFiles(true)
      const response = await backend.put(`/user/${userID}`);
      console.log(response)
      setStoredFiles(response?.data?.UpdateUser?.files)
      // setUserFiles(response?.data?.UpdateUser?.files);
      setFilteredFiles(response?.data?.UpdateUser?.files);
      console.log(response?.data?.UpdateUser?.files);
      await dispatch(setUserData({
        ...response.data.UpdateUser,
        token: UserToken,
        profileExists: UserExists,
      }))
      setLoadingFiles(false)

    } catch (error) {
      console.error("Failed to get data from server", error);

    }
  };

  // Function placeholders
  useEffect(() => { getUserDetails(); }, []);

  console.log(user)

  const DeleteFile = async (id) => {
    // // Assuming storedFiles is an array of file names and you want to remove the file with the given name
    // const newFiles = storedFiles.filter(file => file !== name); // Change this to !== to remove the file
    // const UserId = user._id;
    // setLoadingFiles(true); // Assuming setLoading is a function to manage loading state

    // // Corrected body object to use proper object notation
    // const body = {
    //   files: newFiles,
    // };

    try {
      // Include method to send the body with the request
      const response = await backend.delete(`/deck/${id}`); // Pass body as the second argument to the PUT request
      if (response.status === 200 && response.data) {
        window.location.reload();
      }
      // You might want to do something with the response here, e.g., update UI or state

      setLoading(false);
    } catch (error) {
      console.error(error);
      // Uncomment or modify the toast error message as needed
      // toast.error("Error saving story");
    }
  };

  const handleGoToTemplate = () => {
    // sessionstorage.setItem("navBtn", 'Pitch Deck Templates')
    // window.location.href = "/templates/";
    const link = document.createElement('a');
    link.href = 'https://pitchdecktemplate.s3.us-east-2.amazonaws.com/Ultimate+Pitch+Deck+Template.zip';
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // useEffect(() => {
  //   {
  //     if ((user?.subscription === 'monthly' || user?.subscription === 'yearly') && user.remainingDecks <= 0) {
  //       setopenPurchaseUploadsPopup(true)
  //     } else {
  //       setopenUpgradePopup(true)
  //     }
  //   }
  // }, [])

  return loading ? (
    <div>
      <div className={Classes.banner} >
        <h5 className={Classes.fw600} style={{ color: 'white' }} >.</h5>
      </div>
      <div style={{ background: 'white' }} >
        <div className={Classes.container} >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100vh",
              alignItems: "center",
            }}
          >
            <div id="loading-bar-spinner" class="spinner">
              <div class="spinner-icon" style={{ borderTopColor: onBoarding.color ? onBoarding.color : "red", borderLeftColor: onBoarding.color ? onBoarding.color : "red" }}></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  ) : (
    <>
      <Snackbar open={pdfTypeError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        <Alert severity="error" sx={{ width: '100%' }}>
          Please select a PDF file.
        </Alert>
      </Snackbar>
      <Snackbar open={pdfSizeError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        <Alert severity="error" sx={{ width: '100%' }}>
          File size exceeds 10 MB limit.
        </Alert>
      </Snackbar>
      <MobileVersionPopup />
      {/* {
        <div className={Classes.banner} >
          <h5> {onBoarding.companyName} is more than just a business competition. <span onClick={() => { setopenlearnMorePopup(true) }} style={{ color: onBoarding.color?onBoarding.color:"red" }} >Learn more</span> </h5>
        </div>
      } */}
      <div>
        <div className={Classes.banner} >
          <h5 className={Classes.fw600} style={{ color: 'white' }} >.</h5>
        </div>
        <div style={{ background: 'white' }} >
          <div className={Classes.container} >
            {openlearnMorePopup && <LearnMorePopup closeUpgradePopup={closeLearnMorePopup} closeable={true} />}
            {openUpgradePopup && <UpgradePopup closeUpgradePopup={closeUpgradePopup} closeable={true} />}
            {openPurchaseUploadsPopup && <PurchaseUploadsPopup closePurchaseUploadsPopup={closePurchaseUploadsPopup} />}
            <AnimatePresence>
              {openUploadPopup &&
                <UploadPopup closeUploadPopup={closeUploadPopup} handleImageClicked={handleImageClicked}
                  handleFileChange={handleFileChange}
                />
              }
            </AnimatePresence>
            {userFiles?.length > 0 &&
              <div className={Classes.searchSection} >
                <div className={Classes.remainingTextCon} >
                  {/* <div className={Classes.tooltipCon} >
                <h5 onClick={() => { console.log(filteredFiles) }} className={Classes.sText} >You have <span style={{ color: onBoarding.color?onBoarding.color:"red" }} > {user?.remainingDecks} </span> uploads remaining </h5>
                <div className={Classes.tooltip} >
                  <h5>Limit resets on Monday, June 11, 2024, 4:30pm</h5>
                </div>
              </div> */}
                </div>
                <div className={Classes.searcCon} >
                  <img src="/icons/search.png" alt="icon" />
                  <input type="text" placeholder="Search" />
                </div>
              </div>
            }
            {/* <div className={Classes.searchSection} >
          {user?.subscription === 'monthly' || user?.subscription === 'yearly' ?
            <h5 className={Classes.text2} onClick={() => { setopenPurchaseUploadsPopup(true) }} >Purchase more uploads</h5>
            :
            <h5 className={Classes.text2} onClick={() => { setopenUpgradePopup(true) }} >Upgrade to get more uploads</h5>
          }
        </div> */}

            <div className={Classes.main}>
              <ul className={Classes.cards}>
                {userFiles?.length > 0 ?
                  <li className={Classes.cards_item}>
                    <div className={Classes.card}>
                      <div className={Classes.card_image}><img onClick={handleOpenUploadPopup} src="/upload.png" alt="image" /></div>
                      <div className={Classes.card_content}>

                        {!brochureLoading && uploadBrochure.length == 0 ?
                          <div className={Classes.flex_align} >
                            <span>Upload new deck</span>
                          </div> : null}

                        {brochureLoading &&
                          <>
                            {/* <CircularProgress size={13} style={{ color: "blue" }} /> */}
                            <div className={Classes.flexEnd} >
                              <span>Uploading</span>
                              <div className={Classes.dotsCon} >
                                <div class={Classes.dotFlashing} ></div>
                              </div>
                            </div>
                          </>
                        }

                        {/* {uploadBrochure?.length > 0 && (
                      <Box sx={{ backgroundColor: "#e9ecef", fontSize: "12px", width: "auto" }}>
                        {uploadBrochure?.map((file, i) => (
                          <React.Fragment key={i}>
                            <span style={{ color: "black" }}>{extractFileNames(file?.imgurl).slice(0, 5)}...</span>
                            <DangerousIcon style={{ fontSize: "20px" }} onClick={() => handleRemoveFile(i)} />
                          </React.Fragment>
                        ))}
                      </Box>
                    )} */}
                        <input
                          ref={fileInputRef}
                          onChange={handleInputChange}
                          accept="application/pdf"
                          name="uploadBrochure"
                          className="form-control"
                          type="file"
                          id="formFile"
                          style={{ display: 'none' }}
                        />
                        {
                          !brochureLoading && uploadBrochure.length == 0 ?
                            <a className={Classes.uploadIconCon} onClick={handleOpenUploadPopup} >
                              <img src="/icons/add-white.png" alt="uploadIcons" />
                            </a>
                            :
                            !brochureLoading ?
                              <>
                                {/* <CheckIcon
                              style={{ width: "27px", marginLeft: "auto", height: "27px", fontWeight: "600", cursor: "pointer" }}
                              onClick={(e) => handleSave(e)}
                            /> */}
                                {
                                  <div className={Classes.ConfirmUploadPopup} style={{ display: 'none' }} >
                                    <div className={Classes.confirmCard} >
                                      <div className={Classes.close} onClick={(e) => handleSaveWithoutRedirect(e)} >
                                        <img src='/icons/close.png' alt='close icon' />
                                      </div>
                                      <h3> Almost there... </h3>
                                      <h5> Click on analyze to get slide-by-slide
                                        <br />  analysis of your pitch deck!
                                      </h5>
                                      {/* <div className={Classes.confirmImg} >
                                    {ImageUrl ?
                                      <img src={ImageUrl} />
                                      :
                                      <img src="/icons/big-sample-image.png" alt="slide 1" />
                                    }
                                  </div> */}
                                      <button className={Classes.blueBtn} ref={analyze} onClick={(e) => handleSave(e)} > <h3>Analyze</h3> </button>
                                    </div>
                                  </div>
                                }
                              </>
                              : null
                        }
                      </div>
                    </div>
                  </li>
                  :
                  <div className={Classes.emptyUpload} >
                    {/* When no deck is uploaded */}
                    <img src="./onBoarding/2.png" alt="image" />
                    <h5 className={Classes.emptyUploadTitle} > Upload your first pitch deck </h5>
                    <h5 className={Classes.emptyUploadDesc} >
                      Upload your pitch deck to receive slide-by-slide <br /> feedback and suggestions, a comprehensive summary, <br /> and an overall score.
                    </h5>
                    <div className={Classes.emptyUploadButtons} >
                      <button onClick={handleGoToTemplate} className={Classes.emptyUploadButton1} style={{ border: onBoarding.color ? `1px solid ${onBoarding.color}` : `1px solid red` }} > <h5 style={{ color: onBoarding.color ? onBoarding.color : "red" }} >Download Template</h5> </button>
                      <button onClick={handleOpenUploadPopup} className={Classes.emptyUploadButton2} style={{ background: onBoarding.color ? onBoarding.color : "black" }} > <h5>Upload your deck</h5> </button>
                    </div>
                    {/* {!brochureLoading && uploadBrochure.length == 0 ?
                  <div className={Classes.flex_align} >
                    <span>Upload new deck</span>
                  </div> : null
                } */}

                    {brochureLoading &&
                      <>
                        {/* <CircularProgress size={13} style={{ color: "blue" }} /> */}
                        <div className={Classes.flex_align2} >
                          <span>Uploading</span>
                          <div className={Classes.dotsCon} >
                            <div class={Classes.dotFlashing} ></div>
                          </div>
                        </div>
                      </>
                    }
                    <input
                      ref={fileInputRef}
                      onChange={handleInputChange}
                      accept="application/pdf"
                      name="uploadBrochure"
                      className="form-control"
                      type="file"
                      id="formFile"
                      style={{ display: 'none' }}
                    />
                    {
                      !brochureLoading && uploadBrochure.length == 0 ?
                        <a className={Classes.uploadIconCon} onClick={handleOpenUploadPopup} >
                          {/* <img src="/icons/add-white.png" alt="uploadIcons" /> */}
                        </a>
                        :
                        !brochureLoading ?
                          <>
                            {/* <CheckIcon
                              style={{ width: "27px", marginLeft: "auto", height: "27px", fontWeight: "600", cursor: "pointer" }}
                              onClick={(e) => handleSave(e)}
                            /> */}
                            {
                              <div className={Classes.ConfirmUploadPopup} style={{ display: 'none' }} >
                                <div className={Classes.confirmCard} >
                                  <div className={Classes.close} onClick={(e) => handleSaveWithoutRedirect(e)} >
                                    <img src='/icons/close.png' alt='close icon' />
                                  </div>
                                  <h3> Almost there... </h3>
                                  <h5> Click on analyze to get slide-by-slide
                                    <br />  analysis of your pitch deck!
                                  </h5>
                                  {/* <div className={Classes.confirmImg} >
                                    {ImageUrl ?
                                      <img src={ImageUrl} />
                                      :
                                      <img src="/icons/big-sample-image.png" alt="slide 1" />
                                    }
                                  </div> */}
                                  <button className={Classes.blueBtn} ref={analyze} onClick={(e) => handleSave(e)} > <h3>Analyze</h3> </button>
                                </div>
                              </div>
                            }
                          </>
                          : null
                    }
                  </div>
                }

                {userFiles?.map((value, index) => (
                  <CardItem pdfUrl={value?.url} id={value?._id} index={index} DeleteFile={DeleteFile} moveToAnalyzer={moveToAnalyzer} />
                ))}

              </ul>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default PitchDashboard;