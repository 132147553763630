import React, { useState, useRef } from 'react';
import { motion, easeOut, AnimatePresence } from 'framer-motion';
import Classes from './Auth.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import backend from '../../../api';
import axios from 'axios';
import PitchDeckSuccessPopup from '../../../Global-Components/PitchDeckSuccessPopup/PitchDeckSuccessPopup';
import SizeLimitReachedPopup from '../../Global-Components/SizeLimitReachedPopup/SizeLimitReachedPopup';

export default function Application() {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [pdfSizeError, setpdfSizeError] = useState(false);
  const [uploadBrochure, setUploadBrochure] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const onBoarding = useSelector((state) => state.companyInfo.info);
  const [openSubmittedPopup, setopenSubmittedPopup] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const handleCloseSubmittedPopup = () => {
    navigateTo('/submitted');
  }

  const navigateTo = useNavigate();

  const companyId = onBoarding?._id;
  const userID = user?._id;

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleBgClick = () => {
    // closeUploadPopup();
  };

  const handleCardClick = (event) => {
    event.stopPropagation();
  };

  const handleDropBg = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const fileInputRef = useRef(null);

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files.length > 1) {
      setpdfSizeError(true);
      setTimeout(() => {
        setpdfSizeError(false);
      }, 5000);
      return;
    }
    handleFileChange(files, 'uploadBrochure');
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggingOver(true);
  };

  const handleImageClicked = () => {
    fileInputRef.current.click();
  };

  const [pdfTypeError, setpdfTypeError] = useState(false);
  const [fileName, setfileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const handleFileChange = (files, name) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.type !== 'application/pdf') {
        setpdfTypeError(true);
        return;
      }

      const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
      if (file.size > maxSize) {
        setpdfSizeError(true);
        return;
      }

      setfileName(files[0].name);
      setFileSize((files[0].size / (1024 * 1024)).toFixed(2));

      // closeUploadPopup();
      uploadMedia(files, name);
    }
  };

  const handleInputChange = (e) => {
    const files = e.target.files;
    const name = e.target.name;
    handleFileChange(files, name);
    // Removed duplicate uploadMedia call
  };

  const uploadMedia = async (files, name) => {
    const isValid = Array.from(files).every((file) => file.type.includes("application"));
    console.log(files);
    if (!isValid) {
      alert("File format not supported");
      return;
    }

    try {
      setLoadingFile(true);
      const formData = new FormData();
      Array.from(files).forEach((file) => formData.append("files", file));
      console.log(formData);
      const response = await axios.post("https://evala-server-6356f19fc5a0.herokuapp.com/api/deck/upload", formData);
      console.log(response);

      if (name === "uploadBrochure") {
        const updatedFiles = response.data.data.map((d) => ({ ...d, key: d.name }));
        console.log(updatedFiles);
        setUploadBrochure(updatedFiles[0]?.imgurl);
        // handleSave(updatedFiles[0]?.imgurl);
      }
    } catch (error) {
      alert(error.response?.data?.message || error.message || "An error occurred");
      setLoadingFile(false);
    } finally {
      setLoadingFile(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault()

    const body = {
      url: uploadBrochure,
      userId: userID,
      companyId: companyId,
      videoLink: videoLink,
      adminDeck: false
    };

    console.log(body);
    try {
      const response = await backend.post(`/deck/`, body);
      console.log(response);

      if (response?.data) {
        setLoading(false);
        navigateTo("/submitted");
      }

    } catch (error) {
      console.error("Failed to post data to server", error);
      setLoading(false);
      alert("Failed to save data");
    }
  };

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = 'https://pitchdecktemplate.s3.us-east-2.amazonaws.com/Ultimate+Pitch+Deck+Template.zip';
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const closePopup = () => setpdfSizeError(false);

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <AnimatePresence>
        {pdfSizeError &&
          <SizeLimitReachedPopup closePopup={closePopup} title={'File size is too large'} description={'Please upload a file 10mb or smaller.'} />
        }
      </AnimatePresence>
      <div className={Classes.backgroundImage}>
        <motion.div
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.5,
            ease: easeOut,
          }}
        >
          <div className={Classes.navContainer} style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} >
            <div>
              <a className={Classes.logoLink} href="/">
                <img className={Classes.logoImage} src={onBoarding.logo} alt="Logo" />
              </a>
            </div>
            <nav className={Classes.navMenu}>

            </nav>
            <div>
              <a href={onBoarding.companyWebsite}>
                <h5 className={Classes.goBackTxt} style={{ color: `${onBoarding.color}` }} >Go back to {onBoarding.companyName} </h5>
              </a>
            </div>
          </div>

        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.5,
            ease: easeOut,
            delay: 1,
          }}
        >
          <form onSubmit={handleSave} className={`${Classes.section3}`}>
            <div className={Classes.s4InnerMain} >
              <h5 className={Classes.title} onClick={() => { console.log(fileName) }} > Pitch Deck </h5>
              {fileName &&
                <h5 className={Classes.text2} > {fileName} ({fileSize} MB) </h5>
              }
              <div
                className={Classes.uploadMain}
                onClick={handleBgClick}
                onDrop={handleDropBg}
              >
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                >
                  <div className={Classes.cardCon}
                    style={{ border: isDraggingOver ? '2px dashed green' : '2px dashed #0262DF', borderColor: onBoarding.color ? onBoarding.color : "red" }}
                  >
                    <svg className={Classes.imgCloud} style={{ fill: onBoarding.color }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z" /></svg>
                    <h5 className={Classes.title} >Drag and drop PDF pitch deck file here</h5>
                    <h5 className={Classes.text1} >or</h5>
                    <button onClick={handleImageClicked}
                      type='button'
                      style={{ background: onBoarding.color ? onBoarding.color : "black" }}
                    >
                      <img className={Classes.chooseFile} src='/icons/chooseFile.png' alt="Choose File" />
                      {loadingFile ? <h5>Uploading...</h5> :
                        uploadBrochure ? <h5>{fileName.slice(0, 15)}.pdf</h5> : <h5>Choose file</h5>}
                    </button>
                    <input
                      ref={fileInputRef}
                      onChange={handleInputChange}
                      accept="application/pdf"
                      name="uploadBrochure"
                      className="form-control"
                      type="file"
                      id="formFile"
                      style={{ display: 'none' }}
                    />
                    <h5 className={Classes.text2} >Accepts PDF files up to 10MB</h5>
                    <a href='https://www.ilovepdf.com/powerpoint_to_pdf' target='_blank' rel="noopener noreferrer">
                      <h5 className={Classes.text3}
                        style={{ color: onBoarding.color ? onBoarding.color : "red" }}
                      >Convert PPT to PDF here</h5>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <h5 className={Classes.text5} >
                  Download our ultimate pitch deck template with <br />
                  over 125 premium designed slides.
                </h5>
                <div className='flex' >
                  <h5 className={Classes.text4} style={{ color: onBoarding?.color }} onClick={downloadPdf} >Download here</h5>
                </div>
              </div>
                <div style={{ width: '26rem' }} >
                  <input
                    value={videoLink}
                    type="text"
                    placeholder="Pitch deck video link"
                    required
                    className={`${Classes.inputField} ${Classes.mt15}`}
                    style={{ width: '100%' }}
                    onChange={(e) => setVideoLink(e.target.value)}
                  />
                </div>
              
            </div>
            <footer style={{ background: onBoarding?.color ? onBoarding?.color : 'white' }} >
              <button type="submit" >
                <h5 style={{ color: onBoarding?.color ? onBoarding?.color : 'black' }} >Submit {'>'}</h5>
              </button>
            </footer>
          </form>
        </motion.div>
      </div>
    </>
  );
}
